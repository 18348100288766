<template>
  <div class="container" id="issues_create">
    <h1 v-html="$t('issues_create_title')"></h1>

    <v-form @submit.prevent="submit()" ref="createNewIssueForm">
      <v-row>
        <v-col cols="6">
          <!-- Typ -->
          <!--          atm only GENERAL-->
          <v-select
            id="issues_edit_type"
            v-model="issue.type"
            :label="$t('issue_edit_type')"
            :items="issueTypes"
            :item-title="this.$getLangKey()"
            item-value="name"
            disabled
          />
          <!-- Status -->
          <v-select
            id="issues_edit_status"
            v-model="issue.status"
            :label="$t('issues_edit_status')"
            :items="issueStatuses"
            :item-title="this.$getLangKey()"
            item-value="name"
            disabled
          />
          <!-- Betreff -->
          <v-text-field
            type="text"
            id="issue_edit_subject"
            :label="$t('issue_edit_subject')"
            v-model="issue['subject' + $route.params.upLang]"
            :rules="[rules.required]"
          />
          <!-- Text -->
          <v-textarea filled id="issue_edit_text" :label="$t('issue_edit_text')" v-model="issue['bodyText' + $route.params.upLang]"></v-textarea>
          <!-- Reference (url) -->
          <v-text-field type="text" id="issue_edit_link" :label="$t('issues_edit_display_link')" v-model="issue.reference" />
          <!-- Absender -->
          <div class="search-inline" @click="searchSender()">
            <v-text-field
              id="issue_edit_sender"
              class="padding-right"
              v-model="issue.sender['title' + $route.params.upLang]"
              :label="$t('issue_edit_sender')"
              type="text"
              item-value="id"
              item-text="sender.id"
              clearable
              readonly
              :rules="[rules.required]"
            />
            <v-btn color="primary" class="button-margin"><v-icon>mdi-account-search-outline</v-icon></v-btn>
          </div>
          <!-- Empfänger -->
          <div class="search-inline" @click="searchReceiver()">
            <v-text-field
              id="issue_edit_receiver"
              class="padding-right"
              v-model="issue.recipient['title' + $route.params.upLang]"
              :label="$t('issue_edit_receiver')"
              type="text"
              item-value="id"
              item-text="recipient.id"
              clearable
              readonly
              :rules="[rules.required]"
            />
            <v-btn color="primary" class="button-margin"><v-icon>mdi-account-search-outline</v-icon></v-btn>
          </div>
          <!-- Zugewiesene Person -->
          <v-select
            id="issue_edit_assigned_person"
            :disabled="receiverHasNoValue()"
            v-model="issue.responsibleUser.name"
            :label="$t('issue_edit_assigned_person')"
            :items="responsibleUsers"
            item-title="name"
            item-value="id"
            clearable
          />
          <!-- Fälligkeitsdatum -->
          <dbmDatePicker :dateLabel="$t('issue_edit_due_date')" v-model="issue.dueDate" :clearable="true"></dbmDatePicker>
        </v-col>
      </v-row>
      <!-- Buttons -->
      <v-row>
        <div class="aligned-buttons">
          <v-btn
            color="primary"
            class="shadow-none"
            type="submit"
            :loading="loading"
            :disabled="
              !issue.recipient['title' + $route.params.upLang] || !issue.sender['title' + $route.params.upLang] || !issue['subject' + $route.params.upLang]
            "
            ><span v-html="$t('issues_save')"
          /></v-btn>
          <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('issues_cancel')" /></v-btn>
        </div>
      </v-row>
    </v-form>
    <!-- Search person dialogue -->
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="masterdata-dairies-dialog">
      <v-card>
        <v-card-title><span v-html="$t('search_issues_search_person')" /></v-card-title>
        <v-card-text>
          <!-- search-cards-->
          <v-row class="row search spacer-md">
            <v-col cols="6" md="4" xl="4">
              <search-application-user ref="search-application-user" @search="search" />
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <search-contact ref="search-contact" @search="search" />
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <search-legal-entity ref="search-legal-entity" @search="search" />
            </v-col>
          </v-row>
          <v-btn color="primary" @click="search"><span v-html="$t('masterdata_search')" /></v-btn>
          <v-btn color="secondary" @click="resetSearch"><span v-html="$t('masterdata_reset')" /></v-btn>
          <!-- Search Result table-->
          <v-data-table-server
            v-model="selected"
            item-value="rowKey"
            :headers="fields"
            :items="items"
            :fields="fields"
            show-select
            select-strategy="single"
            :loading="dialogsettings.loading"
          >
          </v-data-table-server>
        </v-card-text>
        <v-card-actions>
          <v-btn id="submit-btn" class="m-2" color="primary" @click="submit()" type="submit"><span v-html="$t('masterdata_dairies_add_button')" /></v-btn>
          <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()" type="submit"><span v-html="$t('close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import SearchLegalEntity from '../../components/searchCards/searchLegalEntity'
import SearchContact from '../../components/searchCards/searchContact'
import SearchApplicationUser from '../../components/searchCards/searchApplicationUser'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'
let searchApplicationUserStore = useSearchApplicationUserStore()
import { Term } from '@/services/term'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { DTSHeader } from '@/services/BackendService'
import { useIssueStatusesStore } from '@/store/enums/IssueStatusesStore'
import { useIssueTypesStore } from '@/store/enums/IssueTypesStore'

const issueMinimal = {
  type: 'GENERAL',
  status: 'TO_DO',
  sender: { id: '' },
  recipient: { id: '' },
  responsibleUser: { id: '', name: '' }
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'issues_create',
  data() {
    return {
      loading: false,

      issue: issueMinimal,
      selected: [],
      items: [],
      dialog: false,
      sender: false,
      receiver: false,
      totalElements: 0,
      dialogsettings: { loading: false, totalElements: 0 },
      fields: <DTSHeader[]>[
        {
          title: this.$t('search_issues_application_users_legal_entity_name1'),
          key: 'legalEntityName1',
          sortable: false
        },
        {
          title: this.$t('search_issues_application_users_legal_entity_name2'),
          key: 'legalEntityName2',
          sortable: false
        }
      ],
      responsibleUsers: [],
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      }
    }
  },
  computed: {
    issueTypes() {
      return useIssueTypesStore().issueTypes
    },
    issueStatuses() {
      return useIssueStatusesStore().issueStatuses
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(searchApplicationUserStore, searchApplicationUserStore.items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    SearchLegalEntity,
    DbmDatePicker,
    SearchContact,
    SearchApplicationUser
  },
  methods: {
    async onSubmit() {
      this.loading = true

      if (!(await this.$refs.createNewIssueForm.validate()).valid) {
        return false
      }
      try {
        const result = await this.axios.post(
          apiURL + '/issues/',
          {
            ...this.issue,
            sender: { id: this.issue.sender.id },
            recipient: { id: this.issue.recipient.id },
            responsibleUser: this.issue.responsibleUser.name ? { id: this.issue.responsibleUser.name } : null
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.$router
            .push({
              name: 'issues_read'
            })
            .catch((e) => {
              /* duplicated route */
            })
          this.reset()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async loadPeople() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/searchLegalEntity', {
          params: {
            term: Term.stringify(this.term),
            ...this.getJavaPageOptions()
          }
        })
        const items = await response.data
        this.items = Array.isArray(items.content) ? items.content : []
        this.items.forEach((item, i) => {
          item.rowKey = i + 1
        })
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async loadResponsibleUsers() {
      this.responsibleUsers = []
      try {
        const response = await this.axios.get(apiURL + '/applicationUsers/findByLegalEntityId', {
          params: {
            legalEntityId: this.issue.recipient?.id
          }
        })
        if (await response) {
          const responsibleUsers = response.data
          this.responsibleUsers = Array.isArray(responsibleUsers) ? responsibleUsers : []
        }
      } catch (e) {
        this.items = []
        showError(e)
      }
    },
    receiverHasNoValue() {
      if (!this.issue.recipient || this.issue.recipient == {} || this.issue.recipient.id == '') {
        return true
      } else return false
    },
    clearReceiver() {
      this.issue.recipient = {}
      this.issue.responsibleUser = {}
    },
    clearSender() {
      this.issue.sender = {}
    },
    showIssueType(type) {
      if (type) {
        let issueType = this.issueTypes.filter((el) => el?.name === type)[0]
        return issueType[this.$getLangKey()]
      } else return ''
    },
    cancel() {
      this.$router
        .push({
          name: 'issues_read'
        })
        .catch((err) => {
          // duplicated route
        })
    },
    searchSender() {
      this.selected = []
      this.dialog = true
      this.sender = true
      this.receiver = false
      this.loading = false
    },
    searchReceiver() {
      this.selected = []
      this.dialog = true
      this.sender = false
      this.receiver = true
      this.loading = false
    },
    submit() {
      this.loading = true
      if (this.sender == true) {
        this.issue.sender.id = this.selected[0].legalEntityId
        this.issue.sender['title' + this.$getUpLang()] = this.selected[0].legalEntityName1
      } else if (this.receiver == true) {
        this.issue.recipient.id = this.selected[0].legalEntityId
        this.issue.recipient['title' + this.$getUpLang()] = this.selected[0].legalEntityName1
        this.loadResponsibleUsers()
      }
      this.dialog = false
      this.sender = false
      this.receiver = false
      this.loading = false
    },
    closeDialog() {
      this.items = []
      this.dialog = false
    },
    reset() {
      this.$refs.createNewIssueForm.reset()
    },
    resetSearch() {
      this.selected = []
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-application-user'].reset()
    },
    // dialogSearch
    async search() {
      this.dialogsettings.loading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/searchLegalEntity', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.dialogsettings.totalElements = await response.data.totalElements
        this.items.forEach((item, i) => {
          item.rowKey = i + 1
        })
      } catch (e) {
        this.items = []
        showError(e)
      } finally {
        this.dialogsettings.loading = false
      }
    }
  },
  beforeMounted() {
    useIssueStatusesStore().fetchIssueStatuses()
    useIssueTypesStore().fetchIssueTypes()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.show-inline {
  display: flex;
}

.clear-button:hover {
  cursor: pointer;
}

.bottom-margin {
  margin-bottom: $spacer-sm;
}

.button-margin {
  margin: -3px !important;
}

.top-margin {
  margin-top: $spacer-xxs;
}

.search-inline {
  display: flex;
}

.padding-right {
  padding-right: $spacer-xxs;
}
</style>
